
import { Options, Vue } from 'vue-class-component';
import { CompanyManagerUserClient, EmployeeClient, ImportDataClient } from '@/services/Services';
import navbar from "@/components/navbar.vue";
import store from '@/store';
import * as VM from '@/viewModel';

@Options({
    components: {
        navbar
    },
})
export default class customerLayout extends Vue {

    created() {
        if(store.state.loggedUser.role == VM.Roles.CompanyManager){
            store.state.baseEmployeeClient = CompanyManagerUserClient;
        } else {
            store.state.baseEmployeeClient = EmployeeClient;
        }
    }

    get scrollable(){
        return this.$route.meta.scrollable ? "scrollable" : "";
    }

}
